@font-face {
	font-family: "nexa-heavy";
	font-display: swap;
	src: url("/src/fonts/nexa-heavy.woff2") format("woff2");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "museo-sans-300";
	font-display: swap;
	src: url("/src/fonts/museo-sans-300.woff2") format("woff2");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "museo-sans-500";
	font-display: swap;
	src: url("/src/fonts/museo-sans-500.woff2") format("woff2");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "museo-sans-700";
	font-display: swap;
	src: url("/src/fonts/museo-sans-700.woff2") format("woff2");
	font-weight: normal;
	font-style: normal;
}
