.text-image {
	@screen md {
		.image-right {
			.picture {
				@apply items-end;
				@apply justify-end;
				@apply pr-8;
			}
		}

		.image-left {
			.picture {
				@apply items-start;
				@apply justify-start;
				@apply pl-8;
			}
		}
	}
}
