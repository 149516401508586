.card-slider {
	.image-box {
		@apply overflow-hidden;
		transform: translateZ(0);
	}

	.image-box img {
		@apply max-w-full;
		@apply w-full;
		@apply opacity-40;
		transition: 0.3s;
		transform: scale(1);
	}

	.image-box:hover img {
		transform: scale(1.1);
	}
}
