.button {
	a {
		@apply text-btn-title;
		@apply text-white;
		@apply cursor-pointer;
		@apply no-underline;
		@apply text-center;

		&.outline {
			@apply border;
			@apply border-solid;
			@apply rounded-full;
			@apply px-5;
			@apply py-1;
			@apply w-full;
		}

		&.flat {
			@apply uppercase;
		}

		&.primary {
			border-radius: 6.25rem;
			@apply bg-primary-500;
			@apply p-btn-primary-title;
			transition: background-color 0.3s;

			&:hover {
				@apply bg-primary-400;
				box-shadow: 0 0.75rem 2rem rgba(0, 0, 0, 0.16);
			}

			&:disabled,
			&.disabled {
				@apply bg-disabled-btn;
			}
		}

		&.secondary {
			border-radius: 6.25rem;
			@apply bg-black;
			@apply p-btn-primary-title;
			transition: background-color 0.3s;

			&:hover {
				box-shadow: 0 0.75rem 2rem rgba(0, 0, 0, 0.16);
				@apply opacity-90;
			}
		}

		&.primary-icon {
			border-radius: 6.25rem;
			@apply bg-primary-500;
			@apply p-btn-primary-icon-title;
			transition: background-color 0.3s;

			svg {
				height: 1.5rem;
				margin-left: 0.5rem;
			}

			&:hover {
				@apply bg-primary-400;
				box-shadow: 0 0.75rem 2rem rgba(0, 0, 0, 0.16);
			}

			&:disabled,
			&.disabled {
				@apply bg-disabled-btn;
			}
		}

		&.floating {
			@apply rounded-full;
			@apply w-14;
			@apply h-14;
			@apply bg-primary-500;
			transition: background-color 0.3s;

			svg {
				height: 1.5rem;
			}

			&:hover {
				@apply bg-primary-400;
				box-shadow: 0 0.75rem 2rem rgba(0, 0, 0, 0.16);
			}
		}

		&.triangle {
			@apply border-solid;
			@apply box-border;
			border-color: transparent transparent transparent theme("colors.body2-dark");
		}
	}
}
