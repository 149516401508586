.section-iframe {
	.iframe-container {
		@apply relative;
		@apply overflow-hidden;
		padding-bottom: 56.25%;
	}

	.iframe-container iframe,
	.iframe-container object,
	.iframe-container embed {
		@apply absolute;
		@apply w-full;
		@apply h-full;
		top: 0;
		left: 0;
	}
}
