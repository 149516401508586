footer {
	.menu-item {
		&.active-tab {
			@apply underline;
		}

		&:hover {
			@apply underline;
		}
	}
}
