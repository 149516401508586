html {
	scroll-behavior: smooth;
}

body {
	min-height: 100%;
	@apply font-museo-sans-500;
}

.content {
	min-height: 100vh;
}

// global swiper flickering fix
.swiper-slide {
	/* stylelint-disable */
	-webkit-transform: translate3d(0, 0, 0);
	/* stylelint-enable */
}

// Image Lazy Load Blurred Preview
img.ls-blur-up-img {
	display: none !important;
}

img.lazyloading.blur-lazy,
img.lazyload.blur-lazy {
	filter: blur(15px);
	transform: translateZ(0);
	transition: filter 0.35s ease-out;
	overflow: hidden;
}

img.lazyloaded.blur-lazy {
	transition: filter 0.35s ease-out;
	transform: translateZ(0);
	filter: blur(0);
}

img.lazyload:not([src]) {
	visibility: hidden;
}

.default-section {
	@apply w-full;
	@apply overflow-hidden;
	// @apply bg-default;
}

// Set max width depending on design
.container {
	max-width: 1224px;
	@apply mx-auto;
	@apply px-4;
}

.large-container {
	@screen md {
		@apply mx-6;
	}
}

.row {
	@apply -mx-3;
	@apply flex;
	@apply flex-wrap;
}

.col {
	@apply px-3;
}

.triangle {
	@apply border-solid;
	@apply box-border;
	@apply inline-block;
	border-color: transparent transparent transparent theme("colors.body2-dark");
}
